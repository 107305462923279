<template>
  <div :class="[b(),className]"
       :style="styleSizeName"
       @click="handleClick">
    <div :id="hid"
         v-if="reload"
         :style="styleChartName"></div>
  </div>
</template>

<script>
import { uuid } from '@/utils/utils';
import create from "../../create";
export default create({
  name: "clapper",
  data () {
    return {
      hid: 'main_' + uuid(),
      reload: true,
      player:'Player_' + uuid(),
      config: {}
    }
  },
  computed: {
    autoplay () {
      return this.option.autoplay
    }
  },
  watch: {
    option:{
      handler(){
        if(typeof this.player!='string' && this.autoplay){
            this.player.play()
          }else if(typeof this.player!='string' && this.autoplay==false){
            this.player.stop()
          }
      },
      deep: true
    },
    dataChart: {
      handler () {
        this.reload = false;
        this.$nextTick(() => {
          this.reload = true;
          if(typeof this.player!='string'){
            this.player.stop()
          }
          setTimeout(() => {
           this.player=new Clappr.Player({
              parentId: '#' + this.hid,
              source: this.dataChart.value,
              autoPlay: true,
              mute: true,
              height: '100%',
              width: '100%'
            });
          })
        })
      },
      deep: true
    }
  },
  beforeDestroy(){
       this.player.stop()
   },
  methods: {
    handleClick () {
      this.clickFormatter && this.clickFormatter({
        data: this.dataChart
      }, this.getItemRefs());
    }
  }
});
</script>



