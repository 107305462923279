<!-- clapper配置 -->
<template>
  <div>
    <el-form-item label="地址">
      <el-input type="textarea"
                rows="5"
                v-model="main.activeObj.data.value">
      </el-input>
      <div @click="main.handleOpenVideo('activeObj.data.value','hls')">
        <i class="el-icon-video-camera-solid"></i>
      </div>
    </el-form-item>
    <el-form-item label="自动播放">
      <avue-switch v-model="main.activeOption.autoplay"></avue-switch>
    </el-form-item>
  </div>
</template>

<script>
export default {
  name: 'clapper',
  inject: ["main"]
}
</script>

<style>
</style>