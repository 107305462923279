<template>
  <div :class="b()">
    <span>{{message}}</span>
  </div>
</template>
<script>
import create from "../../create";
import TTSRecorder from './tts'
export default create({
  name: 'gpt',
  data () {
    return {
      message: '等待指令中...',
      ttsRecorder: null
    }
  },
  computed: {
    group () {
      return this.option.group
    }
  },
  created () {
    this.clientWs()
    this.ttsRecorder = new TTSRecorder()
  },
  methods: {
    clientWs () {
      var host = location.host
      let group = this.group;
      let webSocket = new WebSocket("wss://" + host + "/ws/ws/api");
      let stompClient = Stomp.over(webSocket);
      let closeAudio = window.location.search.split('&')[1];//closeAudio标识让页面在控制器列表时不播报语音
      stompClient.connect({}, (frame) => {
        stompClient.subscribe('/information/' + group, (greeting) => {
          let data = JSON.parse(greeting.body)
          data = JSON.parse(data.message)
          // 判断是否有closeAudio标识
          if(!closeAudio || (closeAudio && closeAudio.indexOf('closeAudio')==-1)){
            this.playAudio(data.message)
          }
          this.message = '';
          const message = data.message.split('')
          // 需要追加到容器中的文字下标
          let index = 0
          const writing = () => {
            if (index < message.length) {
              // 追加文字
              this.message += message[index++]
              let timer = setTimeout(writing, 50)
            }
          }
          writing()
        })
      }, (error) => {
        this.clientWs();
      })
    },
    playAudio (text) {
      this.ttsRecorder.stop()
      this.ttsRecorder.setParams({
        text: text
      })
      this.ttsRecorder.start()
    }
  }
});
</script>
<style>
</style>