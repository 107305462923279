<template>
  <div :id="id"
       :class="[b(),className]"
       :style="styleSizeName"></div>
</template>

<script>
import create from "../../create";
export default create({
  name: 'bdmap',
  data () {
    return {
      text: '',
      map: null,
    }
  },
  mounted () {
    this.init()
    this.$nextTick(() => {
      this.point()
    })
  },
  methods: {
    init () {
      this.map = new BMap.Map(this.id);
      this.map.centerAndZoom(new BMap.Point(116.280, 40.049), this.option.zoom);
      this.map.enableScrollWheelZoom();
    },
    updateChart () {
      this.point()
    },
    point () {
      this.map.clearOverlays()
      this.dataChart.forEach(ele => {
        var opts = {
          position: new BMap.Point(ele.longitude, ele.latitude)
        };
        var marker = new BMap.Marker(opts.position);
        // 创建文本标注对象
        var label = new BMap.Label(ele.address, opts);
        // 自定义文本标注样式
        label.setStyle({
          color: this.option.color,
          borderRadius: '5px',
          borderColor: '#ccc',
          padding: '10px 20px',
          fontSize: this.option.fontSize + 'px',
          fontFamily: '微软雅黑'
        });
        this.map.centerAndZoom(opts.position, this.option.zoom || 18);
        this.map.addOverlay(label);
        this.map.addOverlay(marker);
        marker.setAnimation(BMAP_ANIMATION_BOUNCE);
      })

    }
  }
})
</script>
