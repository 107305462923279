<template>
  <div :class="[b(),className]"
       :style="styleSizeName"
       @click="handleClick">
    <img v-if="reload"
         :style="[styleChartName,styleImgName]"
         :src="dataChart.value"
         :class="b({'rotate': rotate})"
         draggable="false" />
  </div>
</template>

<script>
import create from "../../create";
export default create({
  name: "img",
  data () {
    return {
      reload: true,
      check: null
    }
  },
  computed: {
    styleImgName () {
      return Object.assign(
        (() => {
          if (this.rotate) {
            return {
              animationDuration: this.duration / 1000 + "s"
            };
          }
          return {};
        })(),
        {
          width: '100%',
          height: '100%',
          opacity: this.option.opacity || 1
        }
      );
    },
    refreshTime () {
      return this.option.refreshTime
    },
    refresh () {
      return this.option.refresh;
    },
    duration () {
      return this.option.duration || 3000;
    },
    rotate () {
      return this.option.rotate;
    }
  },
  watch: {
    refresh: {
      handler (val) {
        if (val) {
          this.check = setInterval(() => {
            this.reload = false
            this.$nextTick(() => {
              this.reload = true
            })
          }, this.refreshTime)
        } else {
          clearInterval(this.check)
        }
      },
      immediate: true
    }
  },
  methods: {
    handleClick () {
      this.clickFormatter && this.clickFormatter({
        data: this.dataChart
      }, this.getItemRefs());
    }
  }
});
</script>

