var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[_vm.b(),_vm.className],style:(_vm.styleSizeName),on:{"mouseover":_vm.handleMouseOver,"mouseleave":_vm.handleMouseOut}},[_c('el-table',{ref:"table",style:(_vm.styleChartName),attrs:{"data":_vm.dataChart,"height":_vm.height,"border":_vm.option.border,"cell-style":_vm.cellStyle,"row-style":_vm.rowStyle,"show-header":_vm.showHeader,"header-row-style":_vm.headerRowStyle,"header-cell-style":_vm.headerCellStyle},on:{"cell-click":_vm.cellClick,"row-click":_vm.rowClick}},[(_vm.option.index)?_c('el-table-column',{attrs:{"type":"index","label":"#","header-align":"center","align":"center","width":"60"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var $index = ref.$index;
return _c('span',{},[_vm._v(_vm._s($index+1))])}}],null,false,1805253052)}):_vm._e(),_vm._l((_vm.option.column),function(item,index){return [(item.hide!==true)?_c('el-table-column',{key:index,attrs:{"show-overflow-tooltip":"","prop":item.prop,"label":item.label,"width":item.width},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(item.type=='img')?_c('el-link',{attrs:{"type":"danger","plain":"","size":"small"},on:{"click":function($event){$event.stopPropagation();return _vm.$ImagePreview([{url:row[item.prop]}], 0)}}},[_vm._v("查看图片")]):(item.type=='video')?_c('el-link',{attrs:{"type":"success","size":"small"},on:{"click":function($event){$event.stopPropagation();return _vm.goVideo(row)}}},[_vm._v("查看监控")]):_c('span',[_vm._v(_vm._s(row[item.prop]))])]}}],null,true)}):_vm._e()]})],2),_c('el-dialog',{staticClass:"avue-dialog",attrs:{"visible":_vm.dialog.show,"append-to-body":"","title":"详情","width":"50%"},on:{"update:visible":function($event){return _vm.$set(_vm.dialog, "show", $event)}}},[_c('el-form',{attrs:{"model":_vm.dialog.form,"label-suffix":":"}},_vm._l((_vm.headerList),function(item,index){return _c('el-form-item',{key:index,attrs:{"label":item.label,"readonly":""}},[(item.type=='img')?_c('el-button',{attrs:{"type":"danger","plain":""},on:{"click":function($event){$event.stopPropagation();return _vm.$ImagePreview([{url:item.value}], 0)}}},[_vm._v("查看图片")]):(item.type=='video')?_c('el-button',{attrs:{"type":"success","plain":""},on:{"click":function($event){$event.stopPropagation();return _vm.goVideo(_vm.dialog.form)}}},[_vm._v("查看监控")]):_c('span',[_vm._v(" "+_vm._s(item.value))])],1)}),1)],1),(_vm.video.box)?_c('div',[_c('el-dialog',{staticClass:"avue-dialog",attrs:{"destroy-on-close":"","append-to-body":"","title":_vm.video.title,"visible":_vm.video.box,"width":"80%"},on:{"update:visible":function($event){return _vm.$set(_vm.video, "box", $event)}}},[_c('avue-crud',{attrs:{"option":_vm.video.option,"data":_vm.video.data},scopedSlots:_vm._u([{key:"menu",fn:function(ref){
var row = ref.row;
var size = ref.size;
var type = ref.type;
return [_c('el-button',{attrs:{"size":size,"type":type},on:{"click":function($event){return _vm.goVideo(row)}}},[_vm._v("查看监控")])]}}],null,false,1890758916)})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }