<template>
  <div>
    <el-form-item label="层级">
      <avue-input-number v-model="main.activeOption.zoom"></avue-input-number>
    </el-form-item>
    <el-form-item label="字号">
      <avue-input-number v-model="main.activeOption.fontSize"></avue-input-number>
    </el-form-item>
    <el-form-item label="颜色">
      <avue-input-color v-model="main.activeOption.color"></avue-input-color>
    </el-form-item>
  </div>
</template>

<script>
export default {
  name: 'bdmap',
  inject: ["main"]
}
</script>

<style>
</style>