<template>
  <el-dialog :title="typeClay"
             width="80%"
             class="avue-dialog"
             :close-on-click-modal="false"
             :visible.sync="imgVisible">
    <div class="tabCsliy"
         style="margin: 0 auto">
      <div class="tabArrCnent">
        <el-tag class="m"
                type=""
                v-if="!isConent"
                @click="tabConent(1)"
                :effect="typeOff == 1 ? 'dark' : 'plain'">本地图库</el-tag>
        <el-tag class="m"
                type=""
                v-if="!isConent"
                @click="tabConent(2)"
                :effect="typeOff == 2 ? 'dark' : 'plain'">素材图库</el-tag>
        <el-tag class="m"
                type=""
                v-if="isConent && type == 'video'"
                @click="tabConent(3)"
                :effect="typeOff == 3 ? 'dark' : 'plain'">素材视频</el-tag>
        <el-tag class="m"
                type=""
                v-if="isConent && type == 'hls'"
                @click="tabConent(4)"
                :effect="typeOff == 3 ? 'dark' : 'plain'">素材流视频</el-tag>
        <el-tag v-if="isConent && type == 'hls'"
                @click="selectioned?handleDownload(selectioned):$message.error('请选择')"
                :type='selectioned?"":"info"'>
          选择
        </el-tag>

        <!-- <el-button   type="info" plain disabled>选择</el-button> -->
      </div>
    </div>
    <!-- 图库 -->
    <el-scrollbar v-if="typeOff == 1"
                  class="imgList">
      <img :src="item.value"
           :style="styleName"
           @click="handleSetimg(item.value)"
           v-for="(item, index) in imgOption[imgActive]"
           :key="index" />
    </el-scrollbar>
    <!-- 素材图库 -->
    <el-scrollbar v-if="typeOff == 2"
                  style="margin-top: 20px;"
                  class="imgList">
      <el-upload list-type="picture-card"
                 :action="$website.urlMaterial + '/material/uploadMaterial?type=1'"
                 :file-list="materialListData.records"
                 :on-success="onSuccessMaterialGallery"
                 class="materialGallery"
                 :data="{ type: 1 }"
                 multiple>
        <i slot="default"
           class="el-icon-plus"></i>
        <div slot="file"
             slot-scope="{ file }">
          <img class="el-upload-list__item-thumbnail"
               :src="file.frontPath"
               alt="" />
          <span class="el-upload-list__item-actions">
            <span class="el-upload-list__item-preview"
                  @click="handlePictureCardPreview(file, 1)">
              <i class="el-icon-zoom-in"></i>
            </span>
            <span class="el-upload-list__item-delete"
                  @click="handleDownload(file)">
              <i class="el-icon-check"></i>
            </span>
          </span>
        </div>
      </el-upload>
      <el-pagination background
                     layout="prev, pager, next"
                     @size-change="handleSizeChange"
                     @current-change="handleCurrentChange"
                     :page-sizes="[10, 200, 300, 400]"
                     :page-size="page.size"
                     :total="page.total">
      </el-pagination>
      <el-dialog width="30%"
                 title="查看"
                 :visible.sync="dialogVisible"
                 append-to-body>
        <img width="100%"
             :src="dialogImageUrl"
             alt="" />
      </el-dialog>
    </el-scrollbar>
    <!-- 素材视频 -->
    <el-scrollbar v-if="typeOff == 3"
                  style="margin-top: 20px"
                  class="imgList">
      <el-upload list-type="picture-card"
                 :action="$website.urlMaterial + '/material/uploadMaterial?type=0'"
                 :file-list="videoLibraryData.records"
                 :on-success="onSuccessMaterGalleryvideo"
                 class="materialGallery"
                 :data="{ type: 0 }"
                 multiple>
        <i slot="default"
           class="el-icon-plus"></i>
        <div slot="file"
             slot-scope="{ file }">
             <div class="video-text">{{file.name}}</div>
          <!-- <video class="el-upload-list__item-thumbnail thumbnailVideo"
                 :src="file.url"></video> -->
          <!-- <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" /> -->
          <span class="el-upload-list__item-actions">
            <span class="el-upload-list__item-preview"
                  @click="handlePictureCardPreview(file, 2)">
              <i class="el-icon-zoom-in"></i>
            </span>
            <span class="el-upload-list__item-delete"
                  @click="handleDownload(file)">
              <i class="el-icon-check"></i>
            </span>
          </span>
        </div>
      </el-upload>
      <el-pagination background
                     layout="prev, pager, next"
                     @size-change="handleSizeChange"
                     @current-change="handleCurrentChange"
                     :page-sizes="[10, 200, 300, 400]"
                     :page-size="page.size"
                     :total="page.total">
      </el-pagination>
    </el-scrollbar>
    <el-dialog width="40%"
               title="查看"
               @close="pauseisVideoSee"
               :visible.sync="isVideoSee"
               append-to-body>
      <video width="100%"
             id="video_cosnst"
             v-if="dialogImageUrl && !dialogImageUrl.url"
             :src="dialogImageUrl"
             controls="controls"></video>
      <div v-else
           style="display: inline-block; height: 50vh; width: 100%">
        <videoModel v-if="dialogImageUrl && isVideoSee && dialogImageUrl.url"
                    :item="dialogImageUrl"></videoModel>
      </div>

    </el-dialog>
    <!-- 素材流视频 -->
    <el-scrollbar v-if="typeOff == 4"
                  style="margin-top: 20px;display:block;"
                  class="imgList el-upload-list el-upload-list--picture-card">
      <el-table ref="multipleTable"
                class="avue-crud"
                :data="streamVideoData.records"
                tooltip-effect="dark"
                style="width: 100%"
                stripe>
        <el-table-column type="radio"
                         label
                         width="25">
          <template slot-scope="scope">
            <el-radio :label="scope.row"
                      v-model="selectioned">&nbsp;</el-radio>
          </template>
        </el-table-column>
        <!-- <el-table-column  width="55"> </el-table-column> -->
        <el-table-column prop="identify"
                         label="设备序列号"
                         width="200">
        </el-table-column>
        <el-table-column prop="name"
                         label="设备名称"> </el-table-column>
        <el-table-column prop="url"
                         label="播放地址"> </el-table-column>
        <el-table-column fixed="right"
                         label="操作"
                         width="100">
          <template slot-scope="scope">
            <el-button type="text"
                       @click="handlePictureCardPreview(scope.row, 2, true)"
                       size="small">查看</el-button>
          </template>
        </el-table-column>

      </el-table>
      <el-pagination background
                     layout="prev, pager, next"
                     @size-change="handleSizeChange"
                     @current-change="handleCurrentChange"
                     :page-sizes="[10, 200, 300, 400]"
                     :page-size="page.size"
                     :total="page.total">
      </el-pagination>

    </el-scrollbar>
  </el-dialog>
</template>

<script>
import { imgOption } from "@/option/config";
import {
  materialList,
  materialRemove,
  materialStreamlist,
} from "@/api/material";
import videoModel from "./videoModel";
export default {
  components: {
    videoModel,
  },
  data () {
    return {
      imgVisible: false,
      imgObj: "",
      type: "",
      disabled: false,
      dialogImageUrl: {},
      typeClay: "图库",
      isConent: false,
      // fileList: [{name: 'food.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'}, {name: 'food2.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'}],
      imgActive: 0,
      imgOption: imgOption,
      typeOff: 1, //1 本地图库
      dialogVisible: false,
      isVideoSee: false, //视频图库查看
      imgTabs: [],
      materialListData: [], //素材图库
      videoLibraryData: [], //素材视频库
      streamVideoData: [], //素材流视频
      selectioned: '',
      page: {
        total: 0,
        currentPage: 1,
        page: 1,
        size: 10,
      },
    };
  },
  computed: {
    styleName () {
      if (this.type === "background") {
        return {
          width: "200px",
        };
      }
      return {};
    },
  },
  watch: {
    type: {
      handler () {
        if (this.type === "background") {
          this.imgActive = 0;
        } else if (this.type == "border") {
          this.imgActive = 1;
        } else {
          this.imgActive = 2;
        }
      },
      immediate: true,
    },
  },
  methods: {
    handleCurrentChange (val) {
      this.page.page = val;
      if (this.type == "hls") {
        this.tabConent(4);
      } else if (this.type == "video") {
        this.tabConent(3);
      } else if (this.typeOff == 2) {
        this.tabConent(2);
      }
      console.log(this.type, this.typeOff)
    },
    handleSizeChange (val) {
      this.page.size = val;
      if (this.type == "hls") {
        this.tabConent(4);
      } else if (this.type == "video") {
        this.tabConent(3);
      } else if (this.typeOff == 1) {
        this.tabConent(2);
      }
    },
    pauseisVideoSee () {
      if (document.getElementById("video_cosnst")) {
        document.getElementById("video_cosnst").pause();
      }
    },
    onSuccessMaterialGallery (el) {
      if (el) {
        this.tabConent(2, true);
      }
    },
    onSuccessMaterGalleryvideo (_isVideo) {
      //
      this.tabConent(2, true);
    },
    handlePictureCardPreview (file, type, isTrue) {
      this.dialogImageUrl = file.frontPath;
      if (type == 1) {
        this.dialogVisible = true;
      } else if (type == 2) {
        this.isVideoSee = true;
        if (isTrue === true) {
          this.dialogImageUrl = file;
          return false;
        }
        let video_cosnst = document.getElementById("video_cosnst");
        if (video_cosnst) {
          video_cosnst.load();
          video_cosnst.pause();
        }
      }
    },
    handleDownload (item) {
      this.imgVisible = false;
      // return false;
      //20230626去掉图片路径前缀
      this.$emit("change", item.frontPath, this.imgObj);
    },
    handOponContent (type) {
      let _this = this;
      materialList({
        ...this.page,
        type: type,
      }).then((res) => {
        this.page.total = res.data.total;
        this.page.currentPage = res.data.current;
        if (type == 1) {
          res.data.records = res.data.records.map((element) => {
            return {
              ...element,
              url: this.$website.imgrlMaterial + "/" + element.frontPath,
              name: element.originName,
            };
          });
          _this.materialListData = res.data;
        } else if (type == 0) {
          res.data.records = res.data.records.map((element) => {
            return {
              ...element,
              url: this.$website.imgrlMaterial + "/" + element.frontPath,
              name: element.originName,
            };
          });
          this.videoLibraryData = res.data;
        }
      });
    },
    tabConent (el, type) {
      if (type != true) {
        this.typeOff = el;
      }
      if (el == 2) {
        this.handOponContent(1);
      } else if (el == 3) {
        this.handOponContent(0);
      } else if (el == 4) {
        this.getmaterialStreamlist();
      }
    },
    getmaterialStreamlist () {
      //
      // this.materialStreamlist();
      materialStreamlist(this.page).then((res) => {
        this.page.total = res.data.total;
        this.page.currentPage = res.data.current;
        this.streamVideoData = res.data;
      });
    },
    openImg (item, type, isConent) {
      this.type = type;
      this.imgObj = item;
      this.imgVisible = true;
      this.isConent = isConent;
      this.page = {
        total: 0,
        currentPage: 1,
        page: 1,
        size: 10,
      };
      console.log(item, type, isConent);
      if (type == "hls") {
        this.tabConent(4);
      } else if (isConent == true) {
        this.tabConent(3);
      } else {
        this.typeOff = 1;
      }
    },
    handleSetimg (item) {
      // return false;
      this.imgVisible = false;
      this.$emit("change", item, this.imgObj);
    },
  },
};
</script>

<style>
.tabCsliy .el-tag--plain {
  background-color: transparent !important;
  border-color: #2681ff !important;
}
.tabArrCnent .m {
  margin-right: 20px;
}
.materialGallery .el-upload--picture-card,
.materialGallery .el-upload-list--picture-card .el-upload-list__item {
  width: 100px;
  height: 100px;
  line-height: 100px;
}
.materialGallery .el-upload-list__item img {
  margin: 0 !important;
  padding: 0 !important;
}
.materialGallery .el-upload-list__item.is-success,
.materialGallery .el-upload-list__item.is-ready {
  display: none;
}
.materialGallery .thumbnailVideo {
  width: 100px;
  height: 100px;
}
.materialGallery .el-upload--picture-card {
  display: none;
}
.materialGallery .imgList thead .el-checkbox {
  display: none;
}
.video-text{
  width: 100%;text-align: center;font-size: 12px;
}
</style>