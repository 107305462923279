<!-- GPT配置 -->
<template>
  <div>
    <el-form-item label="组织名称">
      <avue-select v-model="main.activeOption.group"
                   :props="{label:'name',value:'code'}"
                   :dic="dic">
      </avue-select>
    </el-form-item>
  </div>
</template>

<script>
import { url } from '@/config'
export default {
  name: 'gpt',
  inject: ["main"],
  data () {
    return {
      dic: []
    }
  },
  created () {
    this.$axios.get(url + '/org-manage/list?size=100').then(res => {
      this.dic = res.data.records;
    })
  }
}
</script>

<style>
</style>